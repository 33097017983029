import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "@db/axiosInstance";

const seciliSirket = () => {
  const item = window.localStorage.getItem("seciliSirket");
  return item ? JSON.parse(item) : {};
};
const seciliSube = () => {
  const item = window.localStorage.getItem("seciliSube");
  return item ? JSON.parse(item) : {};
};

export const sirketGetir = createAsyncThunk("sirketler", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/Sistem/Sirket/Listesi`
  );
  // console.clear();
  return {
    sirketler: response.data.data,
  };
});

export const subeGetir = createAsyncThunk("subeGetir", async (payload) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/Sistem/Sube/Listesi?sirketId=${payload}`
  );
  return {
    subeler: response.data.data,
  };
});

export const giris = createAsyncThunk("subeGiris", async (payload) => {
  const response = await axios.get(
    "http://localhost:65049/Giris/RefreshToken",
    {
      Headers: {
        RefreshToken: payload,
      },
    }
  );
  return response;
});

export const sirketSube = createSlice({
  name: "sirketSube",
  initialState: {
    sirketler: [],
    subeler: [],
    seciliSirket: seciliSirket(),
    seciliSube: seciliSube(),
    seciliMenu: "",
    sirketSubeVarMi: false,
  },
  reducers: {
    sirketEkle: (state, action) => {
      state.sirketler = action.payload;
    },
    subeEkle: (state, action) => {
      state.subeler = action.payload;
    },
    sirketSec: (state, action) => {
      state.seciliSirket = action.payload;
      localStorage.setItem("seciliSirket", JSON.stringify(action.payload));
    },
    subeSec: (state, action) => {
      state.seciliSube = action.payload;
      localStorage.setItem("seciliSube", JSON.stringify(action.payload));
    },
    menuSec: (state, action) => {
      state.seciliMenu = action.payload;
    },
    sirketSubeEkle: (state, action) => {
      state.sirketSubeVarMi = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sirketGetir.fulfilled, (state, action) => {
        state.sirketler = action.payload.sirketler;
      })
      .addCase(subeGetir.fulfilled, (state, action) => {
        state.subeler = action.payload.subeler;
      });
  },
});

export const {
  sirketEkle,
  subeEkle,
  sirketSec,
  subeSec,
  menuSec,
  sirketSubeEkle,
} = sirketSube.actions;

export default sirketSube.reducer;
