import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "@db/axiosInstance";

export const kullaniciDetay = createAsyncThunk("kullaniciDetay", async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/Auth/Account/GecerliKullanici`
  );
  return {
    kullaniciBilgiler: response.data.data,
  };
});

export const kullaniciDomain = createAsyncThunk(
  "kullaniciDomain",
  async (payload) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/Sistem/Sirket/SistemDomainName?kullaniciId=${payload}`
    );
    return {
      domainBilgisi: response.data.data,
    };
  }
);

export const kullanici = createSlice({
  name: "kullanici",
  initialState: {
    kullaniciBilgiler: {},
    domainBilgisi: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(kullaniciDetay.fulfilled, (state, action) => {
        state.kullaniciBilgiler = action.payload.kullaniciBilgiler;
      })
      .addCase(kullaniciDomain.fulfilled, (state, action) => {
        state.domainBilgisi = action.payload.domainBilgisi;
      });
  },
});

// export const {  } = kullanici.actions;

export default kullanici.reducer;
