import axios from "axios";
import useJwt from "@src/auth/jwt/useJwt";

const apiURL = "https://xysanaliz.xysanaliz.com";

const instance = axios.create({
  baseURL: apiURL,
  headers: { "X-Custom-Header": "xys-react" },
});

// if (location.href.split("/")[3].split("=")[1] !== undefined) {
//   if (location.href.split("/")[3].split("=")[1].length > 150) {
//     useJwt.setToken(location.href.split("/")[3].split("=")[1]);
//     useJwt.setRefreshToken(location.href.split("/")[3].split("=")[1]);
//     useJwt.onAccessTokenFetched(location.href.split("/")[3].split("=")[1]);
//   }
// }
// ** Request Interceptor
instance.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = useJwt.getToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// ** Add request/response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // ** const { config, response: { status } } = error
    const { config, response } = error;
    const originalRequest = config;

    // ** if (status === 401) {
    if (response && response.status === 401) {
      if (!useJwt.isAlreadyFetchingAccessToken) {
        useJwt.isAlreadyFetchingAccessToken = true;
        useJwt.refreshToken().then((r) => {
          useJwt.isAlreadyFetchingAccessToken = false;

          // ** Update accessToken in localStorage
          useJwt.setToken(r.data.accessToken);
          useJwt.setRefreshToken(r.data.refreshToken);

          useJwt.onAccessTokenFetched(r.data.accessToken);
        });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        useJwt.addSubscriber((accessToken) => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`;
          resolve(useJwt.axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default instance;
