// ** Auth Endpoints
const baseUrl = "https://xysanaliz.xysanaliz.com";

export default {
  loginEndpoint: `${baseUrl}/Auth/Account/CreateToken`,
  //loginEndpoint: '/jwt/login',
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${baseUrl}/Auth/Account/RefreshToken`,
  logoutEndpoint: `${baseUrl}/Auth/Account/SignOut`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};
