// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import sirketSube from "./sirketsube";
import kullanici from "./kullanici";

const rootReducer = {
  auth,
  navbar,
  layout,
  sirketSube,
  kullanici,
};

export default rootReducer;
